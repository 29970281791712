@import '~antd/dist/antd.less';

@primary-color: #1E6599;

@success-color: #56B461;
@warning-color: #FABE00;
@error-color: #E62D31;

@alert-error-bg-color: #FCEAEA;
@alert-warning-bg-color: #FFF8E5;
@alert-success-bg-color: #EEF7EF;
@alert-text-color: #949494;

/* Фон страниц */
@layout-body-background: #F6F6F6;

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Light.otf) format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Bold.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-RegularItalic.otf) format('opentype');
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-LightItalic.otf) format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-BoldItalic.otf) format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@font-size-base: 15px;

.ant-layout {
    background-color: #243355;
}

.auth-layout-logo-container {
    margin: 140px auto 80px auto;
}

.login-form {
    width: 400px;
}

.login-form-button {
    width: 100%;
}

.auth-provider-button {
    margin-top: 24px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ant-btn-primary:hover {
    background: #367EB2;
}

.ant-btn-primary:active {
    background: #0D4A75;
}

.ant-form-item-label > label.ant-form-item-required::before {
    color: @error-color;
}
